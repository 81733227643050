import React, { useState, useEffect } from 'react'

import logo from './../assets/images/logo_shiftin_healthcare.svg'
import mail from './../assets/images/ico_contact.svg'
import phone from './../assets/images/ico_phone.svg'
import veeva from './../assets/images/veeva.svg'

import Button from './Button'

function Header() {
    const [small, setSmall] = useState(false)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', () =>
                setSmall(window.pageYOffset > 10)
            )
        }
    }, [])
    return (
        <header className={small && 'headerscroll'}>
            <div className="header page_cont">
                <div className="logo_container">
                    <img src={logo} alt="Shiftin Healthcare" className="logo" />
                    <div className="sep"></div>
                    <img
                        src={veeva}
                        alt="Veeva content partner"
                        className="veeva"
                    />
                </div>
                <a
                    href="mailto:contact@shiftin-healthcare.com"
                    className="headlink"
                >
                    <img src={mail} alt="email" />
                    <strong>contact@shiftin-healthcare.com</strong>
                </a>
                <a href="tel:+213770778655" className="headlink">
                    <img src={phone} alt="phone" />
                    <strong>+ 213 770 77 86 55</strong>
                </a>
                <Button href={'contact'} classNom={'btn marge'}>
                    Nous contacter
                </Button>
            </div>
        </header>
    )
}

export default Header
