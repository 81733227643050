import React from 'react'

import Question from './Question'

function FAQ() {
    return (
        <div className="faq">
            <div className="faq_cont page_cont">
                <div className="description">
                    <h2 className="title">Questions fréquentes</h2>
                </div>
                <div className="questions">
                    <Question
                        title={'Quelle est la taille de votre équipe ? '}
                        color={'#EDFFD0'}
                    >
                        <p>
                            Notre équipe est composée de{' '}
                            <strong>plus de 20 professionnels</strong> de
                            profils divers. Nous avons toutes les compétences en
                            interne : graphistes, développeurs, motion
                            designers, chef de projet, community managers…
                            Aucune sous-traitance, pour un travail en toute
                            confiance ! De plus, les chefs de projet qui seront
                            vos vis-à-vis sont tous{' '}
                            <strong>certifiés Veeva</strong> et donc à même de
                            vous accompagner au mieux.
                        </p>
                    </Question>
                    <Question
                        title={'Quels sont vos tarifs ?'}
                        color={'#D0D5FF'}
                    >
                        <p>
                            Hormis pour quelques services (tels que la
                            réalisation d’ADV), nous n’avons pas de grille
                            tarifaire fixe, nos{' '}
                            <strong>devis se font sur-mesure</strong> selon
                            votre besoin. N’hésitez pas à nous contacter
                            sur&nbsp;
                            <a href="mailto:contact@shiftin-healthcare.com">
                                contact@shiftin-healthcare.com
                            </a>{' '}
                            pour obtenir un devis détaillé.
                        </p>
                    </Question>
                    <Question
                        title={
                            'Avez-vous des experts santé dans votre équipe ?'
                        }
                        color={'#D0F4FF'}
                    >
                        <p>
                            Nnous collaborons avec des médecins en tant que
                            <strong>consultants partenaires</strong>, selon
                            l’aire thérapeutique, la spécialité et le besoin.
                        </p>
                    </Question>
                </div>
            </div>
        </div>
    )
}

export default FAQ
