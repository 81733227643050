import React from 'react'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Sanofi from '../assets/images/logo_sanofi.svg'
import Novo from '../assets/images/logo_novonordisk.svg'
import fethi from '../assets/images/fethi.jpg'
import quote from '../assets/images/quote.svg'

function Temoignages() {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    return (
        <div className="temoignages">
            <div className="courbe"></div>
            <div className="temoignage_cont page_cont">
                <div className="description">
                    <h2 className="title">Témoignages</h2>
                </div>
                <Slider {...settings}>
                    <div>
                        <div className="slickcontent">
                            <div className="slickhead">
                                <div className="pic">
                                    <div className="principal">
                                        <img src={fethi} alt="Fethi AYAD" />
                                    </div>
                                    <img src={quote} alt="" className="quote" />
                                    <img
                                        src={fethi}
                                        alt=""
                                        className="relief"
                                    />
                                </div>
                                <div className="info">
                                    <h4>Fethi AYAD</h4>
                                    <p>Digital Manager, Novo Nordisk Algérie</p>
                                </div>
                            </div>
                            <div className="slickcorp">
                                <p>
                                    Efficacité, professionnalisme et réactivité
                                    sont les qualités que j’utiliserai pour
                                    qualifier l’agence SHIFTIN. Toute l’équipe a
                                    su relever avec brio tous les challenges qui
                                    lui ont été assignés, notamment la mise en
                                    place du nouveau site de la marque Renault,
                                    en s’adaptant avec succès à la contrainte
                                    temps, et fournissant un travail de qualité
                                    irréprochable tant sur le plan technique
                                    qu’opérationnel.
                                </p>
                            </div>
                            <div className="slicklogo">
                                <img src={Novo} alt="NovoNordisk" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="slickcontent">
                            <div className="slickhead">
                                <div className="pic">
                                    <div className="principal">
                                        <img src={fethi} alt="Fethi AYAD" />
                                    </div>
                                    <img src={quote} alt="" className="quote" />
                                    <img
                                        src={fethi}
                                        alt=""
                                        className="relief"
                                    />
                                </div>
                                <div className="info">
                                    <h4>Fethi AYAD</h4>
                                    <p>Digital Manager, Novo Nordisk Algérie</p>
                                </div>
                            </div>
                            <div className="slickcorp">
                                <p>
                                    Efficacité, professionnalisme et réactivité
                                    sont les qualités que j’utiliserai pour
                                    qualifier l’agence SHIFTIN. Toute l’équipe a
                                    su relever avec brio tous les challenges qui
                                    lui ont été assignés, notamment la mise en
                                    place du nouveau site de la marque Renault,
                                    en s’adaptant avec succès à la contrainte
                                    temps, et fournissant un travail de qualité
                                    irréprochable tant sur le plan technique
                                    qu’opérationnel.
                                </p>
                            </div>
                            <div className="slicklogo">
                                <img src={Sanofi} alt="Sanofi" />
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    )
}

export default Temoignages
