import React from 'react'
import Button from './Button'
import Lottie from 'react-lottie'

import kv from '../assets/images/kv.png'
import Sanofi from '../assets/images/logo_sanofi.svg'
import Novo from '../assets/images/logo_novonordisk.svg'
import Servier from '../assets/images/logo_servier.svg'
import Magpharm from '../assets/images/logo_magpharm.svg'
import Pharmalliance from '../assets/images/logo_pharmalliance.svg'
import Fraterrazes from '../assets/images/logo_fraterrazes.svg'
import scroll from '../assets/lottie/scroll'

function Slider() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: scroll,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }
    return (
        <div className="slider_cont">
            <div className="slider page_cont">
                <div className="slide">
                    <div className="content">
                        <h1 className="title">
                            Agence digitale pharma certifiée Veeva en Algérie
                        </h1>
                        <p className="subtitle">
                            Depuis plus de 6 ans, nous accompagnons les acteurs
                            du pharma et de la santé à relever les nouveaux
                            défis du digital.
                        </p>
                        <div className="cta">
                            <Button href={'contact'} classNom="btn">
                                Prendre rendez-vous
                            </Button>
                            <Button href={'services'} classNom="link">
                                En savoir plus
                            </Button>
                        </div>
                    </div>
                    <div className="kv">
                        <div className="deg">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 580 580"
                            >
                                <defs>
                                    <radialGradient
                                        id="a"
                                        cx="0.5"
                                        cy="0.5"
                                        r="0.5"
                                        gradientUnits="objectBoundingBox"
                                    >
                                        <stop offset="0" stopColor="#5ee8ef" />
                                        <stop
                                            offset="1"
                                            stopColor="#5ee8ef"
                                            stopOpacity="0"
                                        />
                                    </radialGradient>
                                </defs>
                                <circle
                                    cx="290"
                                    cy="290"
                                    r="290"
                                    fill="url(#a)"
                                />
                            </svg>
                        </div>
                        <img src={kv} alt="Stethoscope" />
                    </div>
                </div>
                <div className="reference">
                    <a href="sanofi">
                        <img src={Sanofi} alt="Sanofi" />
                    </a>
                    <a href="sanofi">
                        <img src={Novo} alt="Novonordisk" />
                    </a>
                    <a href="sanofi">
                        <img src={Servier} alt="Servier" />
                    </a>
                    <a href="sanofi">
                        <img src={Magpharm} alt="Magpharm" />
                    </a>
                    <a href="sanofi">
                        <img src={Pharmalliance} alt="Pharmalliance" />
                    </a>
                    <a href="sanofi">
                        <img src={Fraterrazes} alt="Fraterrazes" />
                    </a>
                </div>
            </div>
            <div className="scroll">
                <Lottie options={defaultOptions} width={24} />
            </div>
        </div>
    )
}

export default Slider
