import React, { useState } from 'react'

import { useForm } from 'react-hook-form'
import { send } from 'emailjs-com'

const Input = ({ label, name, register, required, error }) => {
    const [focused, setFocused] = useState(false)
    const onFocus = () => setFocused(true)
    const onBlur = (event) => {
        if (event.target.value === '') setFocused(false)
    }
    return (
        <div className={!focused ? 'inputgroup' : 'inputgroup focused'}>
            <label>{label}</label>
            <input
                {...register(name, required)}
                onFocus={onFocus}
                onBlur={onBlur}
                className={!error ? null : 'error_field'}
            />
        </div>
    )
}

const Textarea = ({ label, name, register, required, error }) => {
    const [focused, setFocused] = useState(false)
    const onFocus = () => setFocused(true)
    const onBlur = (event) => {
        if (event.target.value === '') setFocused(false)
    }
    return (
        <div className={!focused ? 'textareagroup' : 'textareagroup focused'}>
            <label>{label}</label>
            <textarea
                {...register(name, { required })}
                onFocus={onFocus}
                onBlur={onBlur}
                className={!error ? null : 'error_field'}
            />
        </div>
    )
}

const Contact = () => {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
    })
    //console.log(errors)
    // const onSubmit = (data) => console.log(data)

    const onSubmit = (data) => {
        send('service_4ru2f91', 'template_4agsmn6', data, '_3osaI68S8nr5yZK4')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text)
            })
            .catch((err) => {
                console.log('FAILED...', err)
            })
    }

    return (
        <div className="contact">
            <div className="contact_cont page_cont">
                <div className="description">
                    <h2 className="title">Prendre contact</h2>
                    <p className="subtitle">
                        Vous avez un projet digital que vous souhaitez réaliser
                        avec des professionnels qui vous comprennent ?
                        Contactez-nous !
                    </p>
                </div>
                <div className="formulaire">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="formrow">
                            <div className="inputcont">
                                <Input
                                    label="Nom & prénom"
                                    name="name"
                                    register={register}
                                    required={{
                                        required: true,
                                        pattern:
                                            /^[A-Za-zàâçéèêëîïôûùüÿñæœ ]+$/i,
                                    }}
                                    error={errors.name}
                                />
                                {errors?.name?.type === 'required' && (
                                    <div className="error">
                                        Champ obligatoire !
                                    </div>
                                )}
                                {errors?.name?.type === 'pattern' && (
                                    <div className="error">Format invalide</div>
                                )}
                            </div>
                            <div className="inputcont">
                                <Input
                                    label="Email"
                                    name="email"
                                    register={register}
                                    required={{
                                        required: true,
                                        pattern:
                                            /^[a-z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,4}$/i,
                                    }}
                                    error={errors.email}
                                />
                                {errors?.email?.type === 'required' && (
                                    <div className="error">
                                        Champ obligatoire !
                                    </div>
                                )}
                                {errors?.email?.type === 'pattern' && (
                                    <div className="error">Format invalide</div>
                                )}
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="inputcont">
                                <Input
                                    label="Entreprise"
                                    name="company"
                                    register={register}
                                />
                            </div>
                            <div className="inputcont">
                                <Input
                                    label="Tel."
                                    name="phone"
                                    register={register}
                                    required={{
                                        required: true,
                                        pattern:
                                            /^((\+[1-9][0-9]*)?|([0]{1,2}))+([-. ]?[0-9]+){9,}$/i,
                                    }}
                                    error={errors.phone}
                                />
                                {errors?.phone?.type === 'required' && (
                                    <div className="error">
                                        Champ obligatoire !
                                    </div>
                                )}
                                {errors?.phone?.type === 'pattern' && (
                                    <div className="error">Format invalide</div>
                                )}
                            </div>
                        </div>
                        <div className="formrow">
                            <div className="inputcont textcont">
                                <Textarea
                                    label="Message"
                                    name="message"
                                    register={register}
                                    error={errors.message}
                                />
                                {errors?.message?.type === 'required' && (
                                    <div className="error">
                                        Champ obligatoire !
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="submitrow">
                            <div className="btn marge">
                                <input type="submit" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact
