import React from 'react'

import RealisationPage from './RealisationPage'
import RealisationRS from './RealisationRS'

import novoPage from '../assets/images/NovoNordisk_page.jpg'
import sanofiPage from '../assets/images/sanofi_page.jpg'
import magpharmPage from '../assets/images/magpharm_page.jpg'
import fraterrazesPage from '../assets/images/fraterrazes_page.jpg'
import genericlabPage from '../assets/images/genericlab_page.jpg'
import solynePage from '../assets/images/solyne_page.jpg'
import alvitylPage from '../assets/images/alvityl_page.jpg'
import sanofiemailingPage from '../assets/images/sanofiemailing_page.jpg'

import Sanofi from '../assets/images/logo_sanofi.svg'
import Magpharm from '../assets/images/logo_magpharm.svg'
import coverFB from '../assets/images/Cover-Facebook.jpg'
import postFB1 from '../assets/images/Ysana-Giant-Square-Stick-FB-1.jpg'
import postFB2 from '../assets/images/Ysana-Giant-Square-Stick-FB-2.jpg'
import postFB3 from '../assets/images/Ysana-Giant-Square-Stick-FB-3.jpg'

function Realisations() {
    return (
        <div className="realisations">
            <div className="realisations_cont page_cont">
                <div className="description">
                    <h2 className="title">Réalisations </h2>
                    <p className="subtitle">
                        Exemples réels et concrets de quelques projets que nous
                        avons réalisés pour nos clients.
                    </p>
                </div>
                <div className="real">
                    <RealisationPage
                        title={'Novo Nordisk'}
                        desc={'Plateform Web'}
                        img={novoPage}
                        dimension={'demi'}
                        backgroundColor={'#EDFFD0'}
                        backgroundPosition={'140px 120px'}
                        backgroundSize={'120%'}
                        type={'scroll'}
                    />
                    <RealisationPage
                        title={'Sanofi'}
                        desc={'Plateform Web'}
                        img={sanofiPage}
                        logo={Sanofi}
                        dimension={'demi'}
                        backgroundColor={'#D9D0FF'}
                        backgroundPosition={'center'}
                        backgroundSize={'90%'}
                        type={'hover'}
                        color={'#F4F2F6'}
                    />

                    <RealisationPage
                        title={'Magpgarm'}
                        desc={'Site web institutionnel'}
                        img={magpharmPage}
                        logo={Magpharm}
                        dimension={'tiere'}
                        backgroundColor={'#FFEBD0'}
                        backgroundPosition={'right -150px top 50%'}
                        backgroundSize={'90%'}
                        type={'hover'}
                        color={'#F7F7F7'}
                    />
                    <RealisationPage
                        title={'Frater-Razes'}
                        desc={'Site web institutionnel'}
                        img={fraterrazesPage}
                        dimension={'tiere'}
                        backgroundColor={'#D0FAFF'}
                        backgroundPosition={'right -100px top 100%'}
                        backgroundSize={'120%'}
                        type={'scroll'}
                    />
                    <RealisationPage
                        title={'Genericlab'}
                        desc={'Site web institutionnel'}
                        img={genericlabPage}
                        dimension={'tiere'}
                        backgroundColor={'#D0D5FF'}
                        backgroundPosition={'right -100px top 100%'}
                        backgroundSize={'120%'}
                        type={'scroll'}
                    />

                    <RealisationPage
                        title={'Solyne'}
                        desc={'Site web marque'}
                        img={solynePage}
                        dimension={'demi'}
                        backgroundColor={'#FFEFD0'}
                        backgroundPosition={'top -30px left -250px'}
                        backgroundSize={'100%'}
                        type={'scroll'}
                    />
                    <RealisationPage
                        title={'Alvityl'}
                        desc={'Site web marque'}
                        img={alvitylPage}
                        dimension={'demi'}
                        backgroundColor={'#D0FFEF'}
                        backgroundPosition={'top 140px  left 120px'}
                        backgroundSize={'120%'}
                        type={'scroll'}
                    />

                    <RealisationPage
                        title={'Sanofi'}
                        desc={'Emailing'}
                        img={sanofiemailingPage}
                        logo={Sanofi}
                        dimension={'tiere'}
                        backgroundColor={'#D0D5FF'}
                        backgroundPosition={'top 50px left -50px'}
                        backgroundSize={'90%'}
                        type={'hover'}
                        color={'#F4F2F6'}
                    />
                    <RealisationRS
                        title={'Ysana'}
                        desc={'Réseaux sociaux'}
                        cover={coverFB}
                        img1={postFB1}
                        img2={postFB2}
                        img3={postFB3}
                        dimension={'tiere_double'}
                        backgroundColor={'#D0F4FF'}
                        backgroundPosition={'top 50px right -100px'}
                        backgroundSize={'80%'}
                    />
                </div>
            </div>
        </div>
    )
}

export default Realisations
