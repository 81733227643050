import React from 'react'

import veeva from '../assets/images/veeva.svg'

import groupe169 from '../assets/images/Groupe 169.svg'
import groupe170 from '../assets/images/Groupe 170.svg'
import groupe171 from '../assets/images/Groupe 171.svg'
import one from '../assets/images/one.svg'
import two from '../assets/images/two.svg'
import three from '../assets/images/three.svg'

function Maitrise() {
    return (
        <div className="maitrise">
            <div className="maitrise_cont page_cont">
                <div className="description">
                    <img
                        src={veeva}
                        alt="Veeva content Partner Multichannel CRM"
                    />
                    <h2 className="title">
                        La maîtrise de Veeva en Algérie, au plus près de vous
                    </h2>
                    <p className="subtitle">
                        Pour besoin de faire appel à un prestataire étranger peu
                        accessible, avec des problèmes de langue, de fuseaux
                        horaires et de compréhension.
                    </p>
                    <p className="subtitle">
                        Simplifiez-vous la vie en faisant confiance à un
                        partenaire algérien, avec des équipes certifiées, que
                        vous pouvez joindre à tout moment au téléphone ou en
                        face-to-face.
                    </p>
                </div>
                <div className="details">
                    <div>
                        <img src={groupe169} alt="" className="icon" />
                        <h3>
                            <img src={one} alt="1" />
                            <span>Présentations CLM (ADV) Veeva</span>
                        </h3>
                        <p>
                            Réalisation d’ADV 100% compatibles Veeva intégrant
                            toutes les fonctionnalités
                        </p>
                        <ul>
                            <li>Statistiques et insights</li>
                            <li>Interactivité et navigation</li>
                            <li>Animations </li>
                            <li>Compatibles Veeva CRM et Vault PromoMats </li>
                        </ul>
                    </div>
                    <div>
                        <img src={groupe170} alt="" className="icon" />
                        <h3>
                            <img src={two} alt="2" />
                            <span>Approved Emails Veeva</span>
                        </h3>
                        <p>
                            Conception d’emails personnalisés pour renforcer le
                            lien avec les professionnels de la santé
                        </p>
                        <ul>
                            <li>Emails validés (médical et légal)</li>
                            <li>Compliance assurée</li>
                            <li>Traçabilité et statistiques</li>
                            <li>Intégration Veeva</li>
                        </ul>
                    </div>
                    <div>
                        <img src={groupe171} alt="" className="icon" />
                        <h3>
                            <img src={three} alt="3" />
                            <span>Veeva MyInsights</span>
                        </h3>
                        <p>
                            Configuration du tracking et statistiques avancés
                            pour une analyse de données stratégique
                        </p>
                        <ul>
                            <li>Visualisation de données</li>
                            <li>Dashboards personnalisés</li>
                            <li>Real-time data</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Maitrise
